import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const PrivacyPolicyPage = lazy(() => import('src/pages/privacy-policy'));
const TermsAndConditionPage = lazy(() => import('src/pages/terms-and-condition'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const PaymentPage = lazy(() => import('src/pages/payment'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));

// BLOG
const BlogListPage = lazy(() => import('src/pages/blog/list'));
const PostDetailsPage = lazy(() => import('src/pages/blog/detail'));


export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-and-condition', element: <TermsAndConditionPage /> },
    ],
  },
  {
    element: (
      <MainLayout>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </MainLayout>
    ),
    children: [
      { path: 'pricing', element: <PricingPage /> },
      { path: 'payment', element: <PaymentPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
  {
    element: (
      <MainLayout>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </MainLayout>
    ),
    path: 'blog',
    children: [
      { element: <BlogListPage />, index: true },
      { path: 'blog', element: <BlogListPage /> },
      { path: ':title', element: <PostDetailsPage /> },
    ],
  },
];
