import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import courseServices from './courseServices';

const initialState = {
  courses:[],
  sections:[],
  courseContents:[],
  singleCourse:{},
  coureProgress:{},
  enrolledUserPrograss:[],
  courseStatistics:{},
  continueCourse:[],
  totalRecord: 0,
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingSection:false,
  message: '',
};

export const addNewCourse = createAsyncThunk('addNewCourse', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.addCourse(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCourse = createAsyncThunk(
  'getCourse',
  async ({pageno,perPage}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.getCourse(user.accesstoken,pageno, perPage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleCourse = createAsyncThunk(
  'getSingleCourse',
  async (courseId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.getSingleCourse(user.accesstoken,courseId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPublishCourse = createAsyncThunk(
  'getPublishCourse',
  async ({ pageno, perPage }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.getPublishCourse(user.accesstoken, pageno, perPage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewCourses = createAsyncThunk('getReviewCourses', async ({ pageno, perPage }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.getReviewCourses(user.accesstoken, pageno, perPage);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const enrolledCourseGet = createAsyncThunk(
  'enrolledCourseGet',
  async ({ pageno, perPage }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.enrolledCourseGet(user.accesstoken, pageno, perPage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCourse = createAsyncThunk('updateCourse', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.updateCourse(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCourseStatus = createAsyncThunk('updateCourseStatus', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.updateCourseStatus(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getUserCourseProgress = createAsyncThunk(
  'getUserCourseProgress',
  async (courseId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.getUserCourseProgress(user.accesstoken,courseId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const enrolledCourse = createAsyncThunk('enrolledCourse', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.enrolledCourse(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const enrolledUserProgressGet = createAsyncThunk('enrolledUserProgressGet', async (courseId, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await courseServices.enrolledUserProgressGet(user.accesstoken, courseId);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

  export const addNewSection = createAsyncThunk('addNewSection', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.addSection(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const getSection = createAsyncThunk('getSection', async (courseId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.getSection(user.accesstoken, courseId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const updateSection = createAsyncThunk('updateSection', async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.updateSection(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const deleteSection = createAsyncThunk('deleteSection', async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.deleteSection(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const addNewLecture = createAsyncThunk('addNewLecture', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.addLecture(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const updateLecture = createAsyncThunk('updateLecture', async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.updateLecture(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const deleteLecture = createAsyncThunk('deleteLecture', async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.deleteLecture(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const markLectureComplete = createAsyncThunk('markLectureComplete', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.markLectureComplete(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const addContent = createAsyncThunk('addContent', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.addContent(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const updateContent = createAsyncThunk('updateContent', async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.updateContent(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const deleteContent = createAsyncThunk('deleteContent', async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await courseServices.deleteContent(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const courseContentGet = createAsyncThunk(
    'courseContentGet',
    async (courseId, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await courseServices.courseContentGet(user.accesstoken,courseId);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const userCourseStatisticsGet = createAsyncThunk(
    'userCourseStatisticsGet',
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await courseServices.userCourseStatisticsGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const userCoursesProgressGet = createAsyncThunk(
    'userCoursesProgressGet',
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await courseServices.userCoursesProgressGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );



export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isLoadingSection = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courses = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.courses = [];
      })
      .addCase(getPublishCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPublishCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courses = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
      })
      .addCase(getPublishCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.courses = [];
      })
      .addCase(getUserCourseProgress.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserCourseProgress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coureProgress = action?.payload?.body;
      })
      .addCase(getUserCourseProgress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.coureProgress = {};
      })
      .addCase(getReviewCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviewCourses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courses = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
      })
      .addCase(getReviewCourses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.courses = [];
      })
      .addCase(enrolledCourseGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enrolledCourseGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courses = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
      })
      .addCase(enrolledCourseGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.courses = [];
      })
      .addCase(getSingleCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleCourse = action?.payload?.body;
      })
      .addCase(getSingleCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.singleCourse ={};
      })
      .addCase(enrolledCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enrolledCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleCourse = action?.payload?.body;
      })
      .addCase(enrolledCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.singleCourse ={};
      })
      .addCase(enrolledUserProgressGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enrolledUserProgressGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enrolledUserPrograss = action?.payload?.body;
      })
      .addCase(enrolledUserProgressGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.enrolledUserPrograss =[];
      })
      .addCase(updateCourseStatus.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(updateCourseStatus.fulfilled, (state, action) => {
        state.isLoadingSection = false;
        state.isSuccess = true;
        state.singleCourse = action?.payload?.body;
      })
      .addCase(updateCourseStatus.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addNewSection.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(addNewSection.fulfilled, (state, action) => {
        state.sections = state.sections.concat({ ...action?.payload?.body, lectures: [] });
        state.isError = false;
        state.isLoadingSection = false;
      })
      .addCase(addNewSection.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sections=action?.payload?.body
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateSection.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?._id
            ? action?.payload?.body
            : section
        );
        state.isLoadingSection = false;
      })
      .addCase(updateSection.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteSection.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections  = state.sections.filter((section) => section._id !== action?.payload?.body?._id);
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addNewLecture.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(addNewLecture.fulfilled, (state, action) => {
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? { ...section, lectures: [...section.lectures, action.payload.body] }
            : section
        );
        state.isError = false;
        state.isLoadingSection = false;
      })
      .addCase(addNewLecture.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateLecture.pending, (state) => {
        state.isLoadingSection = false;
      })
      .addCase(updateLecture.fulfilled, (state, action) => {
        state.isLoadingSection = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                lectures: section.lectures.map((lecture) =>
                  lecture._id === action.payload.body._id ? { ...lecture, ...action.payload.body} : lecture
                ),
              }
            : section
        );
      })
      .addCase(updateLecture.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteLecture.pending, (state) => {
        state.isLoadingSection = false;
      })
      .addCase(deleteLecture.fulfilled, (state, action) => {
        state.isLoadingSection = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                lectures: section.lectures.filter((lecture) => lecture._id !== action.payload.body._id),
              }
            : section
        );
      })
      .addCase(deleteLecture.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(markLectureComplete.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(markLectureComplete.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(markLectureComplete.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addContent.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(addContent.fulfilled, (state, action) => {
        state.sections = state.sections.map((section) => {
          if (section._id === action.payload.body.lecture.section) {
            return {
              ...section,
              lectures: section.lectures.map((lecture) => {
                if (lecture._id === action.payload.body.lecture._id) {
                  return {
                    ...lecture,
                    content:action.payload.body,
                  };
                }
                return lecture;
              }),
            };
          }
          return section;
        });
        state.isError = false;
        state.isLoadingSection = false;
      })
      .addCase(addContent.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateContent.pending, (state) => {
        state.isLoadingSection = false;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.isLoadingSection = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) => {
          if (section._id === action.payload.body.lecture.section) {
            return {
              ...section,
              lectures: section.lectures.map((lecture) => {
                if (lecture._id === action.payload.body.lecture._id) {
                  return {
                    ...lecture,
                    content:action.payload.body,
                  };
                }
                return lecture;
              }),
            };
          }
          return section;
        });
        state.isError = false;
        state.isLoadingSection = false;
      })
      .addCase(updateContent.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteContent.pending, (state) => {
        state.isLoadingSection = true;
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        state.isLoadingSection = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) => {
          if (section._id === action.payload.body.lecture.section) {
            return {
              ...section,
              lectures: section.lectures.map((lecture) => {
                if (lecture._id === action.payload.body.lecture._id) {
                  return {
                    ...lecture,
                    content: lecture.content._id === action.payload.body._id ? null : lecture.content,
                  };
                }
                return lecture;
              }),
            };
          }
          return section;
        });
        state.isError = false;
      })
      .addCase(deleteContent.rejected, (state, action) => {
        state.isLoadingSection = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(courseContentGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(courseContentGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courseContents=action?.payload?.body
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(courseContentGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(userCourseStatisticsGet.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(userCourseStatisticsGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courseStatistics = action?.payload?.body;
      })
      .addCase(userCourseStatisticsGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.courseStatistics = {};
      })
      .addCase(userCoursesProgressGet.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(userCoursesProgressGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.continueCourse = action?.payload?.body;
      })
      .addCase(userCoursesProgressGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.continueCourse = [];
      });
  },
});
export const { resets } = courseSlice.actions;

export default courseSlice.reducer;
