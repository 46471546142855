import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';


// CLASSIC
const LoginClassicPage = lazy(() => import('src/pages/auth-demo/classic/login'));
const RegisterClassicPage = lazy(() => import('src/pages/auth-demo/classic/register'));
const ForgotPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/forgot-password'));
const VerifyClassicPage = lazy(() => import('src/pages/auth-demo/classic/verify'));
const NewPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/new-password'));
const VerifyAccountClassicPage = lazy(() => import('src/pages/auth-demo/classic/verifyAccount'));


const authClassic = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginClassicPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="">
          <RegisterClassicPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordClassicPage /> },
        { path: 'new-password', element: <NewPasswordClassicPage /> },
        { path: 'verify', element: <VerifyClassicPage /> },
        { path: 'verify-account', element: <VerifyAccountClassicPage /> },
      ],
    },
  ],
};


export const authDemoRoutes = [
  {
    path: '',
    children: [authClassic],
  },
];
