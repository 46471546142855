import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

// Create Conversation api 
const createConversation = async (token, receiver_id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/conversation/create`,
    receiver_id,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};
// Get all Conversation api
const allConversation = async (token,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/conversation/list?limit=${limit ?? 20}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};
// Get conversation by id
const getConversationById = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/conversation?id=${data.id}&pageno=${data.page}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};
// Create Group api 
const createGroup = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/conversation/create/group`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Create Group api failed");
};

// add Group Member api
const addGroupMember = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/conversation/group/member`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Group Member api failed");
};

// Get conversation attachement by id
const getConversationAttachmentById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/conversation/attachment?id=${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Get Conversation Attachment api failed");
};

// User Mark the message read
const markAllMessagesAsRead = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/conversation/ready/messages/${id}`,{},
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Mark Message read api failed");
};

const chatServices = {
  createConversation,
  allConversation,
  getConversationById,
  createGroup,
  addGroupMember,
  getConversationAttachmentById,
  markAllMessagesAsRead
};

export default chatServices;
