import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moduleServices from './moduleServices';

const initialState = {
  modules:[],
  activeModule:[],
  totalRecord: 0,
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addNewModule = createAsyncThunk('addNewModule', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await moduleServices.addModule(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getBusinessModule = createAsyncThunk(
  'getBusinessModule',
  async ({business,pageno,perPage,search}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await moduleServices.getBusinessModule(user.accesstoken,business,pageno, perPage,search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateModule = createAsyncThunk('updateModule', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await moduleServices.updateModule(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateStatusModule = createAsyncThunk('updateStatusModule', async ({id,data} ,thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await moduleServices.updateStatusModule(user.accesstoken,id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const activeModuleList = createAsyncThunk(
  "activeModuleList",
  async (bussiness,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await moduleServices.activeModule(
        user.accesstoken,bussiness
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBusinessModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.modules = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
      })
      .addCase(getBusinessModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogs = [];
      })
      .addCase(updateModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateStatusModule.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateStatusModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.modules = state.modules.map((module) =>
            module?._id === action.payload.body._id ? action.payload.body : module
          );
      })
      .addCase(updateStatusModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeModuleList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeModuleList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeModule =action?.payload?.body;
      })
      .addCase(activeModuleList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeModule = [];
      })
  },
});
export const { resets } = moduleSlice.actions;

export default moduleSlice.reducer;
