import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  leave:icon('ic_leave'),
};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard.dashboard,
            icon: ICONS.dashboard,
          },
          {
            title: t('Activity Feed'),
            path: paths.dashboard.activity,
            icon: ICONS.analytics,
          },
        ],
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('Business'),
            path: paths.dashboard.business,
            icon: ICONS.ecommerce,
          },
          {
            title: t('Branch'),
            path: paths.dashboard.branch,
            icon: ICONS.label,
          },
          {
            title: t('user'),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
          },
          {
            title: t('Projects'),
            path: paths.dashboard.projects,
            icon: ICONS.product,
            children: [
              { title: t('list'), path: paths.dashboard.projects},
              { title: t('Board'), path: paths.dashboard.board},
              { title: t('Modules'), path: paths.dashboard.module },
              { title: t('Versions'), path: paths.dashboard.version },
            ],
          },
          {
            title: t('Attendance'),
            path: paths.dashboard.attendance,
            icon: ICONS.analytics,
          },
          {
            title: t('Leave'),
            path: paths.dashboard.leave,
            icon: ICONS.leave,
          },
          {
            title: t('Chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },
          {
            title: t('Pay Roll'),
            path: paths.dashboard.payrole,
            icon: ICONS.file,
          },
          {
            title: t('Category'),
            path: paths.dashboard.category,
            icon: ICONS.order,
          },
          {
            title: t('Service'),
            path: paths.dashboard.service,
            icon: ICONS.tour,
          },
          {
            title: t('invoice'),
            path: paths.dashboard.invoice.root,
            icon: ICONS.invoice,
          },
          {
            title: t('Blog'),
            path: paths.dashboard.blog,
            icon: ICONS.blog,
          },
          {
            title: t('University'),
            path: paths.dashboard.course,
            icon: ICONS.banking,
          }
        ],
      },
    ],
    [t]
  );

  return data;
}

export function useNavSuperAdminData() {
  const { t } = useLocales();

  const superAdminData = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.superAdmin.dashboard,
            icon: ICONS.dashboard,
          },
        ],
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('Business'),
            path: paths.superAdmin.business,
            icon: ICONS.ecommerce,
          },
          {
            title: t('Business Type'),
            path: paths.superAdmin.businessType,
            icon: ICONS.menuItem,
          },
          {
            title: t('Branch'),
            path: paths.superAdmin.branch,
            icon: ICONS.label,
          },
          {
            title: t('Category'),
            path: paths.superAdmin.category,
            icon: ICONS.order,
          },
          {
            title: t('Service'),
            path: paths.superAdmin.service,
            icon: ICONS.tour,
          },
          {
            title: t('user'),
            path: paths.superAdmin.user.list,
            icon: ICONS.user,
          },
          {
            title: t('Projects'),
            path: paths.superAdmin.projects,
            icon: ICONS.product,
          },
          {
            title: t('Pay Roll'),
            path: paths.superAdmin.payrole,
            icon: ICONS.invoice,
          },
          {
            title: t('Attendance'),
            path: paths.superAdmin.attendance,
            icon: ICONS.analytics,
          },
          {
            title: t('invoice'),
            path: paths.superAdmin.invoice.root,
            icon: ICONS.invoice,
          },
          {
            title: t('Blog Category'),
            path: paths.superAdmin.blogCategory,
            icon: ICONS.menuItem,
          },
          {
            title: t('Blog'),
            path: paths.superAdmin.blog,
            icon: ICONS.blog,
          },
          {
            title: t('Course'),
            path: paths.superAdmin.course,
            icon: ICONS.banking,
          }
        ],
      },
    ],
    [t]
  );

  return superAdminData;
}