import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addSection = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/section/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Section api failed');
};

const userSectionTask = async (token,project) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/section/task/${project}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Section List api failed');
};

const updateSection = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/section/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Section api failed');
};

const updateSectionPositon = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/section/postion/update`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Position Section api failed');
};

const deleteSection = async (token, id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  };
  const response = await axios.delete(`${BASE_URL}/admin/section/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete Section api failed');
};

const copySection = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/section/copy`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Copy Section api failed');
};

const addTask = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/task/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Task api failed');
};

const updateTask = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Task api failed');
};

const updateTaskPosition = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/postion/update`,data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Task  Position api failed');
};

const updateTaskStatus = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/section/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Task Status api failed');
};

const deleteTask = async (token, id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  };
  const response = await axios.delete(`${BASE_URL}/admin/task/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete task api failed');
};

const copyTask = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/task/copy`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Copy Task api failed');
};

const assignTask = async (token,id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/assignee/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Assign Task api failed');
};

const assignVersionTask = async (token,id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/task/assign/version/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Assign Version/Module Task api failed');
};

const addLabel = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/label/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Label api failed');
};

const getLabel  = async (token,project) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/label/${project}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Label List api failed');
};

const updateLabel = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/label/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated label api failed');
};

const assignLabel = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/label/assign`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Assign Label api failed');
};

const addCheckList = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/checkList/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add CheckList api failed');
};

const checkListGet = async (token,task) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/checkList/${task}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('CheckList List api failed');
};

const deleteCheckList = async (token, id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  };
  const response = await axios.delete(`${BASE_URL}/admin/checkList/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete CheckList api failed');
};

const updateCheckList = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/checkList/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated CheckList api failed');
};

const addCheckListItem = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/checkListItem/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add CheckList Item api failed');
};

const updateCheckListItem = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/checkListItem/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated CheckListItem api failed');
};
const updateCheckListItemPositon = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/checkListItem/position/update`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Position CheckListItem api failed');
};
const deleteCheckListItem = async (token, id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  };
  const response = await axios.delete(`${BASE_URL}/admin/checkListItem/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete CheckListItem api failed');
};

const addAttachment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/attachment/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Attachment api failed');
};

const attachmentGet = async (token,task) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/attachment/${task}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Attachment List api failed');
};

const deleteAttachment = async (token, id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  const response = await axios.delete(`${BASE_URL}/admin/attachment/${id}`,config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete attachment api failed');
};

const addComment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/comment/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Comment api failed');
};

const getComment = async (token,task) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/comment/${task}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Comment List api failed');
};

const updateComment = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/comment/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Comment api failed');
};

const deleteComment = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${BASE_URL}/admin/comment/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Delete Comment api failed');
};

const taskTimeLogHistoryGet = async (token,taskId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/timelog/history/${taskId}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('TimeLog History api failed');
};


const usertaskTimeLogGet = async (token,taskId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/user/timelog/${taskId}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('User TimeLog api failed');
};

const taskTimeLog = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/timelog/task`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Task TimeLog api failed');
};

const checkoutTaskTimeLogUpdate = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/checkout/task/timelog`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Task TimeLog Checkout api failed');
};


const kanbanServices = {
  addSection,
  userSectionTask,
  updateSection,
  updateSectionPositon,
  deleteSection,
  copySection,
  addTask,
  updateTask,
  updateTaskStatus,
  updateTaskPosition,
  deleteTask,
  copyTask,
  assignTask,
  assignVersionTask,
  addLabel,
  getLabel,
  updateLabel,
  assignLabel,
  addCheckList,
  checkListGet,
  deleteCheckList,
  updateCheckList,
  addCheckListItem,
  updateCheckListItem,
  updateCheckListItemPositon,
  deleteCheckListItem,
  addAttachment,
  attachmentGet,
  deleteAttachment,
  addComment,
  getComment,
  updateComment,
  deleteComment,
  taskTimeLogHistoryGet,
  usertaskTimeLogGet,
  taskTimeLog,
  checkoutTaskTimeLogUpdate
};

export default kanbanServices;
