import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;
// Add Blog
const addBlog = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/blog/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Blog api failed"); 
};
// Get Blog
const getBlog = async (token,pageno) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/admin/blog?page=${pageno}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Blog List api failed"); 
  };

  // Update Blog
  const updateBlog = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/blog/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Blog api failed"); 
  };

// Public Blog
  const publicBlog = async () => {  
    const response = await axios.get(
      `${BASE_URL}/superAdmin/blog/public`
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Public Blog api failed"); 
  };
  // Delete Blog
  const deleteBlog  = async (token,id,data) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };  
    const response = await axios.delete(
      `${BASE_URL}/admin/blog/${id}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Delete Blog api failed"); 
  };
  // Get Search Blog
  const getSearchBlog = async (token,search) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/blog/search?search=${search}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Blog List api failed"); 
    };
    // Publish Request
    const isPublishRequestBlog = async (token,pageno) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };  
      const response = await axios.get(
        `${BASE_URL}/superAdmin/blog/isPublishRequest?page=${pageno}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Get isPublish Request Blog api failed"); 
    };
    // Toggle is Publish
    const toggleisPublishBlog = async (token,id,data) => { 
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }; 
      const response = await axios.put(
        `${BASE_URL}/superAdmin/blog/toggle/publish/${id}`,
        data,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Updated toggle Publish api failed"); 
    };
    // Get Super Admin Search Blog
    const getSuperAdminSearchBlog = async (token,search) => {  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
        const response = await axios.get(
          `${BASE_URL}/superAdmin/blog/search?search=${search}`,
          config
        );
        if (response.data) {
          return response.data;
        } 
        throw new Error("Blog List api failed"); 
      };
      // Get Publish Search Blog
    const searchPublishBlogGet = async (search) => {  
        const response = await axios.get(
          `${BASE_URL}/superAdmin/blog/public/search?search=${search}`
        );
        if (response.data) {
          return response.data;
        } 
        throw new Error("Search Publish Blog List api failed"); 
      };
  // Get Single Blog
  const getSingleBlog = async (id) => {  
      const response = await axios.get(
        `${BASE_URL}/admin/blog/single/${id}`,
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Get Single Blog  api failed"); 
  };
  // Recent Blog
  const recentBlog = async () => {  
    const response = await axios.get(
      `${BASE_URL}/superAdmin/recent/blog`
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Recent Blog api failed"); 
  };
      

const blogServices = {
    addBlog,
    getBlog,
    updateBlog,
    publicBlog,
    deleteBlog,
    getSearchBlog,
    isPublishRequestBlog,
    toggleisPublishBlog,
    getSuperAdminSearchBlog,
    searchPublishBlogGet,
    getSingleBlog,
    recentBlog
};

export default blogServices;
