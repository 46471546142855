import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serviceServices from "./serviceServices";

const initialState = {
  services:[],
  activeService:[],
  businessAgainstService:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewServices = createAsyncThunk(
    "addNewServices",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices.addService(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserServices = createAsyncThunk(
    "getUserServices",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices.userService(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateServices = createAsyncThunk(
    "updateServices",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices.updateService(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getActiveService = createAsyncThunk(
    "getActiveService",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices. activeService(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allServices = createAsyncThunk(
    "allServices",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices.allService(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getBusinessAganistService = createAsyncThunk(
    "getBusinessAganistService",
    async (businessId,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await serviceServices. businessAganistService(user.accesstoken,businessId);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewServices.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addNewServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserServices.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserServices.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.services =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getUserServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.services = [];
      })
      .addCase(updateServices.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getActiveService.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getActiveService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeService = action?.payload?.body;
      })
      .addCase(getActiveService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allServices.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allServices.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.services =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(allServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.services = [];
      })
      .addCase(getBusinessAganistService.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getBusinessAganistService.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessAgainstService =action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(getBusinessAganistService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.services = [];
      })
  },
});
export const { resets } = serviceSlice.actions;

export default serviceSlice.reducer;
