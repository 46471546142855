import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import businessServices from "./businessServices";

const initialState = {
  businesses:[],
  activeBusiness:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewBusiness = createAsyncThunk(
    "addNewBusiness",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.addBusiness(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserBusiness = createAsyncThunk(
    "getUserBusiness",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.userBusiness(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateBusiness = createAsyncThunk(
    "updateBusiness",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.updateBusiness(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const activeBusinessList = createAsyncThunk(
    "activeBusinessList",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.activeBusiness(
          user.accesstoken
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getAllBusiness = createAsyncThunk(
    "getAllBusiness",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.allBusiness(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getAssignBusiness = createAsyncThunk(
    "getAssignBusiness",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessServices.assignBusiness(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserBusiness.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businesses =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getUserBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businesses = [];
      })
      .addCase(updateBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getAllBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBusiness.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businesses =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getAllBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businesses = [];
      })
      .addCase(getAssignBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignBusiness.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businesses =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getAssignBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businesses = [];
      })
      .addCase(activeBusinessList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeBusinessList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeBusiness =action?.payload?.body;
      })
      .addCase(activeBusinessList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeBusiness = [];
      })
  },
});
export const { resets } = businessSlice.actions;

export default businessSlice.reducer;
