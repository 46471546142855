import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addBusiness = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/business/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Business api failed"); 
};

const userBusiness = async (token,pageno,limit,search) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/admin/user/business?page=${pageno}&perPage=${limit}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Business List api failed"); 
  };

  const updateBusiness = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/business/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Business api failed"); 
  };

  const singleBusinessData = async (token,id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/business/${id}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Single Business api failed"); 
  };

  const activeBusiness = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/user/business/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Business api failed"); 
  };


  const allBusiness = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/superAdmin/business/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Business List api failed"); 
  };

  const assignBusiness = async (token,pageno,limit,search) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/bussiness/assign?page=${pageno}&perPage=${limit}&search=${search??''}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Assign Business List api failed"); 
    };

const businessServices = {
    addBusiness,
    userBusiness,
    updateBusiness,
    singleBusinessData,
    allBusiness,
    activeBusiness,
    assignBusiness
};

export default businessServices;
