import axios from 'axios';
import { userConstants } from './userConstants';

/* eslint-disable */
axios.interceptors.request.use(
    function (config) {
      config.headers[userConstants.tokenVariable] = localStorage.getItem(userConstants.tokenVariable);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  /* eslint-disable */
  axios.interceptors.response.use(
    function (response) {
      const token = response?.headers[userConstants.tokenVariable];
      if (token) localStorage.setItem(userConstants.tokenVariable, token);
      return response;
    },
    function (error) {
      if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem(userConstants.tokenVariable);
        localStorage.removeItem('persist:root');
        window.location.replace('/');
      }
      return Promise.reject(error);
    }
  );
  
  export default axios;