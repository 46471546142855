import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import projectVersionServices from './projectVersionServices';

const initialState = {
  projectVersion:[],
  activeProjectVersion:[],
  singleProjectVersion:{},
  projectModule:[],
  totalRecord: 0,
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addProjectVersion = createAsyncThunk('addProjectVersion', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.addProjectVersion(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getProjectVersion = createAsyncThunk(
  'getProjectVersion',
  async ({projectId,pageno,perPage}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await projectVersionServices.getProjectVersion(user.accesstoken,projectId,pageno, perPage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProjectVersion = createAsyncThunk('updateProjectVersion', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.updateProjectVersion(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateStatusProjectVersion = createAsyncThunk('updateStatusProjectVersion', async ({id,data} ,thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.updateStatusProjectVersion(user.accesstoken,id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const activeProjectVersionList = createAsyncThunk(
  "activeProjectVersionList",
  async (projectId,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await projectVersionServices.getActiveProjectVersion(
        user.accesstoken,projectId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleProjectVersion = createAsyncThunk(
  "getSingleProjectVersion",
  async (id,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await projectVersionServices.getSingleProjectVersion(
        user.accesstoken,id
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProjectModule = createAsyncThunk('updateProjectModule', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.updateProjectModule(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const projectModuleGet = createAsyncThunk(
  'projectModuleGet',
  async (projectId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await projectVersionServices.projectModuleGet(user.accesstoken,projectId);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addProjectModule = createAsyncThunk('addProjectModule', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.addProjectModule(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteProjectModule = createAsyncThunk('deleteProjectModule', async ({id,project}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await projectVersionServices.deleteProjectModule(user.accesstoken,project,id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const projectVersionSlice = createSlice({
  name: 'projectVersion',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProjectVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProjectVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.projectVersion = state.projectVersion.concat({ ...action?.payload?.body });
      })
      .addCase(addProjectVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getProjectVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectVersion.fulfilled, (state, action) => {
        state.projectVersion = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(getProjectVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.projectVersion = [];
      })
      .addCase(updateProjectVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProjectVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectVersion =state.projectVersion.map((item) =>
          item._id === action.payload.body._id ? action.payload.body : item
        );
      })
      .addCase(updateProjectVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateStatusProjectVersion.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateStatusProjectVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectVersion =state.projectVersion.map((item) =>
          item._id === action.payload.body._id ? action.payload.body : item
        );
      })
      .addCase(updateStatusProjectVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeProjectVersionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeProjectVersionList.fulfilled, (state, action) => {
        state.activeProjectVersion = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(activeProjectVersionList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSingleProjectVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProjectVersion.fulfilled, (state, action) => {
        state.singleProjectVersion = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(getSingleProjectVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateProjectModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProjectModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeProjectVersion = action.payload.body;
      })
      .addCase(updateProjectModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(projectModuleGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(projectModuleGet.fulfilled, (state, action) => {
        state.projectModule = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(projectModuleGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addProjectModule.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addProjectModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.activeProjectVersion = action.payload.body;
      })
      .addCase(addProjectModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteProjectModule.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteProjectModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.activeProjectVersion = action.payload.body;
      })
      .addCase(deleteProjectModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});
export const { resets } = projectVersionSlice.actions;

export default projectVersionSlice.reducer;
