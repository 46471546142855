
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import attendanceServices from "./attendanceServices";

const initialState = {
  attendances:[],
  monthlyHours:{},
  todayAttendance:null,
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewAttendance = createAsyncThunk(
    "addNewAttendance",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.addAttendance(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserAttendance = createAsyncThunk(
    "getUserAttendance",
    async ({pageno,limit,id,fromDate,toDate,business},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.userAttendance(user.accesstoken,id,pageno,limit,fromDate,toDate,business);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getTodayAttendance = createAsyncThunk(
    "getTodayAttendance",
    async (businessId,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.getUserTodayAttendance(user.accesstoken,businessId);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getBussinessUserAttendance = createAsyncThunk(
    "getBussinessUserAttendance",
    async ({pageno,limit,id,businessId,fromDate,toDate},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.getBussinessUserAttendanceList(user.accesstoken,id,businessId,pageno,limit,fromDate,toDate);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allAttendance = createAsyncThunk(
    "allAttendance",
    async ({pageno,limit,fromDate,toDate},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.allAttendance(user.accesstoken,pageno,limit,fromDate,toDate);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getTotalDurationForCurrentMonth = createAsyncThunk(
    "getTotalDurationForCurrentMonth",
    async ({business,userId,startDate,endDate},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await attendanceServices.getTotalDurationForCurrentMonth(user.accesstoken,business,userId,startDate,endDate);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // const newAttendance = action.payload;
        // const existingIndex = state.attendances.findIndex(
        //   (property) => property._id=== newAttendance.body._id
        // );
        // if (existingIndex !== -1) {
        //   state.attendances[existingIndex] = action.payload.body;
        // } else {
        //   state.attendances.push(newAttendance.body);
        // }
      })
      .addCase(addNewAttendance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.attendances =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getUserAttendance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businesses = [];
      })
      .addCase(allAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.attendances =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(allAttendance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.attendances = [];
      })
      .addCase(getTodayAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTodayAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.todayAttendance =action?.payload?.body;
      })
      .addCase(getTodayAttendance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBussinessUserAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBussinessUserAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.attendances =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getBussinessUserAttendance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.attendances = [];
      })
      .addCase(getTotalDurationForCurrentMonth.pending, (state) => {
        state.isLoading =false;
      })
      .addCase(getTotalDurationForCurrentMonth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.monthlyHours =action?.payload?.body;
      })
      .addCase(getTotalDurationForCurrentMonth.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.monthlyHours = {};
      }) 
  },
});
export const { resets } = attendanceSlice.actions;

export default attendanceSlice.reducer;
