
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addModule = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/module/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Module api failed"); 
};

const getBusinessModule = async (token,business,pageno,perPage,search) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/admin/module/${business}?page=${pageno}&perPage=${perPage}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Module List api failed"); 
  };

  const updateModule = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/module/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Module api failed"); 
  };

  const updateStatusModule = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/module/status/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Module api failed"); 
  };

  const activeModule = async (token,bussiness) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/active/module/${bussiness}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Module api failed"); 
  };

const moduleServices = {
    addModule,
    getBusinessModule,
    updateModule,
    updateStatusModule,
    activeModule
};

export default moduleServices;
