import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogCategoryServices from "./blogCategoryServices";

const initialState = {
  blogCategories:[],
  activeBlogCategories:[],
  totalRecord:0,
  totalPages:0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

  export const addBlogCategory = createAsyncThunk(
    "addBlogCategory",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogCategoryServices.addBlogCategory(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const blogCategory = createAsyncThunk(
    "blogCategory",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogCategoryServices.blogCategory(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const updateBlogCategory = createAsyncThunk(
    "updateBlogCategory",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogCategoryServices.updateBlogCategory(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const activeBlogCategory = createAsyncThunk(
    "activeBlogCategory",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogCategoryServices.activeBlogCategory(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const blogCategorySlice = createSlice({
  name: "blogCategory",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBlogCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addBlogCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addBlogCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(blogCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(blogCategory.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.blogCategories =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.totalPages =action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(blogCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogCategories = [];
      })
      .addCase(updateBlogCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateBlogCategory.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateBlogCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeBlogCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(activeBlogCategory.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.activeBlogCategories =action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(activeBlogCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeBlogCategories = [];
      })
  },
});
export const { resets } = blogCategorySlice.actions;

export default blogCategorySlice.reducer;
