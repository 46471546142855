import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addUser = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };      
  const response = await axios.post(
    `${BASE_URL}/admin/businessUser/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add User api failed"); 
};

const businessUser = async (token,pageno,limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };        
    const response = await axios.get(
      `${BASE_URL}/admin/user/businessUser?page=${pageno}&perPage=${limit}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("BusinessUser List api failed"); 
  };

  const updateUser = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.put(
      `${BASE_URL}/admin/businessUser/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated User api failed"); 
  };

  const activeBussinessUser = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/admin/user/businessUser/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Active BusinessUser api failed"); 
  };

  const businessClient = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/admin/user/businessUser/client`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("BusinessClient List api failed"); 
  };

  const businessEmployee = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/admin/user/businessUser/employee`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("BusinessEmployee List api failed"); 
  };

  const assignedBusinessUserList = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };      
    const response = await axios.get(
      `${BASE_URL}/admin/assignedBusinessUser`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Assigned Business List api failed"); 
  };

  const allBusinessUser = async (token,pageno,limit,search) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };      
    const response = await axios.get(
      `${BASE_URL}/superAdmin/businessUser/all?page=${pageno}&perPage=${limit}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("BusinessUser List api failed"); 
  };

  const businessClientAganistBusiness = async (token,businessId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/businessUser/against/${businessId}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Client Aganist Business api failed"); 
  };


  const businessUserSearchAgainstBusiness = async (token,businessId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/businessUser?businessId=${businessId}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Business User Search api failed"); 
  };


const userServices = {
    addUser,
    businessUser,
    updateUser,
    activeBussinessUser,
    businessClient,
    allBusinessUser,
    assignedBusinessUserList,
    businessEmployee,
    businessClientAganistBusiness,
    businessUserSearchAgainstBusiness
};

export default userServices;
