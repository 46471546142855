import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addLeave = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/leave/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Leave api failed');
};

const userleave = async (token, pageno, limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/user/leave?page=${pageno ?? 1 }&perPage=${limit ??30}&search=${search ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('User Leave List api failed');
};

const userEmployeeLeave = async (token, pageno, limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/empolyee/leave?page=${pageno}&perPage=${limit}&search=${search ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Empolyee Leave List api failed');
};
const updateleaveStatus = async (token,id,data) => {
    const config = {                
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };     
    const response = await axios.put(
      `${BASE_URL}/admin/leave/status/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("leave Leave api failed"); 
  };

  
const userStatisticsleave = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/user/leave/statistic`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('User Statistic Leave List api failed');
};

const empolyeeStatisticsleave = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/empolyee/leave/statistic`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Empolyee Statistic Leave List api failed');
};

const leaveServices = {
  addLeave,
  userleave,
  userEmployeeLeave,
  updateleaveStatus,
  userStatisticsleave,
  empolyeeStatisticsleave
};

export default leaveServices;
