import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addPayrol = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/admin/payrol/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Payrol api failed"); 
};

const userPayrol = async (token,pageno,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };  
    const response = await axios.get(
      `${BASE_URL}/admin/user/payrol?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Payrol List api failed"); 
  };

  const updatePayrol = async (token,id,data) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.put(
      `${BASE_URL}/admin/payrol/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Payrol api failed"); 
  };

  const deletePayrol = async (token,id) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.delete(
      `${BASE_URL}/admin/payrol/${id}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Delete Payrol api failed"); 
  };

  const allPayrol = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/superAdmin/payrol/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Payrol List api failed"); 
  };

const payroleServices = {
    addPayrol,
    userPayrol,
    updatePayrol,
    deletePayrol,
    allPayrol
};

export default payroleServices;
