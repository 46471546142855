/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import kanbanServices from './kanbanServices';

const initialState = {
  sections: [],
  tasks: [],
  labels: [],
  checkList: [],
  attachment: [],
  comments:[],
  timeLogHistory:[],
  userTimeLog:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  isloadingCheckList:false,
  isloadinglabel:false,
  isloadingDetail:false,
  isloadingComment:false,
  isloadingCopyTask:false,
  isloadingTimeLog:false,
  message: '',
};

export const addNewSection = createAsyncThunk('addNewSection', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addSection(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getuserSectionTask = createAsyncThunk('getuserSectionTask', async (project,thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.userSectionTask(user.accesstoken,project);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSection = createAsyncThunk('updateSection', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.updateSection(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSectionPosition = createAsyncThunk('updateSectionPositon', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.updateSectionPositon(user.accesstoken,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteSection = createAsyncThunk('deleteSection', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteSection(user.accesstoken, id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const copySection = createAsyncThunk('copySection', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.copySection(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const addTask = createAsyncThunk('addTask', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addTask(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTask = createAsyncThunk('updateTask', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.updateTask(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTaskPostion = createAsyncThunk('updateTaskPostion', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.updateTaskPosition(user.accesstoken,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTaskStatus = createAsyncThunk(
  'updateTaskStatus',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await kanbanServices.updateTaskStatus(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk('deleteTask', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteTask(user.accesstoken, id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const copyTask = createAsyncThunk('copyTask', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.copyTask(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const assignTask = createAsyncThunk('assignTask', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.assignTask(user.accesstoken,id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const assignVersionTask = createAsyncThunk('assignVersionTask', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.assignVersionTask(user.accesstoken,id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addLabel = createAsyncThunk('addLabel', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addLabel(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getLabel = createAsyncThunk('getLabel', async (project,thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.getLabel(user.accesstoken,project);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateLabel = createAsyncThunk('updateLabel', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.updateLabel(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const assignLabel = createAsyncThunk('assignLabel', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.assignLabel(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addCheckList = createAsyncThunk('addCheckList', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addCheckList(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCheckList = createAsyncThunk('getCheckList', async (task, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.checkListGet(user.accesstoken, task);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteCheckList = createAsyncThunk('deleteCheckList', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteCheckList(user.accesstoken, id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCheckList = createAsyncThunk(
  'updateCheckList',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await kanbanServices.updateCheckList(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addCheckListItem = createAsyncThunk('addCheckListItem', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addCheckListItem(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCheckListItem = createAsyncThunk(
  'updateCheckListItem',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await kanbanServices.updateCheckListItem(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCheckListItemPositon = createAsyncThunk(
  'updateCheckListItemPositon',
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await kanbanServices.updateCheckListItemPositon(user.accesstoken,data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCheckListItem = createAsyncThunk('deleteCheckListItem', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteCheckListItem(user.accesstoken, id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addAttachment = createAsyncThunk('addAttachment', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addAttachment(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const attachmentGet = createAsyncThunk('attachmentGet', async (task, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.attachmentGet(user.accesstoken, task);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteAttachment = createAsyncThunk('deleteAttachment', async ({id,data}, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteAttachment(user.accesstoken, id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const addComment = createAsyncThunk('addComment', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.addComment(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getComment = createAsyncThunk('getComment', async (task, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.getComment(user.accesstoken, task);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteComment = createAsyncThunk('deleteComment', async (id, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.deleteComment(user.accesstoken, id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateComment = createAsyncThunk(
  'updateComment',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await kanbanServices.updateComment(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskTimeLogHistoryGet = createAsyncThunk('taskTimeLogHistoryGet', async (task, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.taskTimeLogHistoryGet(user.accesstoken, task);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const usertaskTimeLogGet = createAsyncThunk('usertaskTimeLogGet', async (task, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.usertaskTimeLogGet(user.accesstoken, task);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const taskTimeLog = createAsyncThunk('taskTimeLog', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.taskTimeLog(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const checkoutTaskTimeLogUpdate = createAsyncThunk('checkoutTaskTimeLogUpdate', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await kanbanServices.checkoutTaskTimeLogUpdate(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isloadingCheckList=false;
      state.isloadingTimeLog=false;
      state.isloadingComment=false;
      state.isloadinglabel=false;
      state.isloadingCopyTask=false;
      state.isloadingDetail=false,
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewSection.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.sections = state.sections.concat({ ...action?.payload?.body, task: [] });
      })
      .addCase(addNewSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getuserSectionTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getuserSectionTask.fulfilled, (state, action) => {
        state.sections = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(getuserSectionTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(copySection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(copySection.fulfilled, (state, action) => {
        state.sections = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(copySection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSection.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?._id
            ? { ...section, name: action?.payload?.body?.name }
            : section
        );
      })
      .addCase(updateSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateSectionPosition.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSectionPosition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = action?.payload?.body
      })
      .addCase(updateSectionPosition.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteSection.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.filter(
          (section) => section._id !== action?.payload?.body?._id
        );
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addTask.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? { ...section, task: [...section.task, action.payload.body] }
            : section
        );
      })
      .addCase(addTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateTask.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body._id ? { ...task, ...action.payload.body} : task
                ),
              }
            : section
        );
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(assignVersionTask.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(assignVersionTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body._id ? { ...task, ...action.payload.body} : task
                ),
              }
            : section
        );
      })
      .addCase(assignVersionTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateTaskStatus.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTaskStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = action.payload.body;
      })
      .addCase(updateTaskStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteTask.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.filter((task) => task._id !== action.payload.body._id),
              }
            : section
        );
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(copyTask.pending, (state) => {
        state.isloadingCopyTask = true;
      })
      .addCase(copyTask.fulfilled, (state, action) => {
        state.sections = action?.payload?.body;
        state.isSuccess = true;
        state.isloadingCopyTask = false;
      })
      .addCase(copyTask.rejected, (state, action) => {
        state.isloadingCopyTask = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assignTask.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(assignTask.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body._id ? { ...task, ...action.payload.body} : task
                ),
              }
            : section
        );
      })
      .addCase(assignTask.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addLabel.pending, (state) => {
        state.isloadinglabel = true;
      })
      .addCase(addLabel.fulfilled, (state, action) => {
        state.isloadinglabel = false;
        state.isSuccess = true;
        state.isError = false;
        state.labels = state.labels.concat(action?.payload?.body);
      })
      .addCase(addLabel.rejected, (state, action) => {
        state.isloadinglabel = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getLabel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLabel.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.labels = action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(getLabel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateLabel.pending, (state) => {
        state.isloadinglabel = true;
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        state.isloadinglabel = false;
        state.isSuccess = true;
        state.isError = false;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.task?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body.task._id ? { ...task, ...action.payload.body.task } : task
                ),
              }
            : section
        );
        state.labels = state?.labels?.map((label) =>
          label._id === action.payload.body.label._id ? action.payload.body.label : label);
      })
      .addCase(updateLabel.rejected, (state, action) => {
        state.isloadinglabel = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(assignLabel.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(assignLabel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body._id ? { ...task, ...action.payload.body } : task
                ),
              }
            : section
        );
      })
      .addCase(assignLabel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addCheckList.pending, (state) => {
        state.isloadingCheckList = true;
      })
      .addCase(addCheckList.fulfilled, (state, action) => {
        state.isloadingCheckList = false;
        state.isSuccess = true;
        state.isError = false;
        state.checkList = state.checkList.concat({ ...action?.payload?.body, checklistitem: [] });
      })
      .addCase(addCheckList.rejected, (state, action) => {
        state.isloadingCheckList = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getCheckList.pending, (state) => {
        state.isloadingDetail = true;
      })
      .addCase(getCheckList.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.checkList = action?.payload?.body;
        state.isloadingDetail = false;
      })
      .addCase(getCheckList.rejected, (state, action) => {
        state.isloadingDetail = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteCheckList.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCheckList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.checkList = state.checkList.filter(
          (checkList) => checkList._id !== action?.payload?.body?._id
        );
      })
      .addCase(deleteCheckList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateCheckList.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCheckList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateCheckList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addCheckListItem.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(addCheckListItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkList = state.checkList.map((checkList) =>
          checkList._id === action?.payload?.body?.checklist
            ? { ...checkList, checklistitem: [...checkList.checklistitem.filter(item => item._id),action.payload.body,{ checklist:checkList._id, description: '',checked: false  }] }
            : checkList
        );
      })
      .addCase(addCheckListItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCheckListItem.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCheckListItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.checkList = state.checkList.map((checkList) =>
          checkList._id === action?.payload?.body?.checklist
            ? {
                ...checkList,
                checklistitem: checkList.checklistitem.filter(item => item._id).map((checklistitem) =>
                  checklistitem._id === action.payload.body._id ? { ...checklistitem, ...action.payload.body } : checklistitem
                ),
              }
            : checkList
        );
      })
      .addCase(updateCheckListItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateCheckListItemPositon.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCheckListItemPositon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.checkList = action?.payload?.body;
      })
      .addCase(updateCheckListItemPositon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteCheckListItem.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCheckListItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.checkList = state.checkList.map((checkList) =>
          checkList._id === action?.payload?.body?.checklist
            ? {
                ...checkList,
                checklistitem: checkList.checklistitem.filter(item => item._id).filter(
                  (checklistitem) => checklistitem._id !== action.payload.body._id
                ),
              }
            : checkList
        );
      })
      .addCase(deleteCheckListItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addAttachment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(addAttachment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.attachment =action?.payload?.body;
      })
      .addCase(addAttachment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(attachmentGet.pending, (state) => {
        state.isloadingDetail = true;
      })
      .addCase(attachmentGet.fulfilled, (state, action) => {
        state.attachment = action?.payload?.body;
        state.isloadingDetail = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(attachmentGet.rejected, (state, action) => {
        state.isloadingDetail = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteAttachment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAttachment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.attachment = state.attachment.filter(
          (attachment) => attachment._id !== action?.payload?.body?._id
        );
      })
      .addCase(deleteAttachment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addComment.pending, (state) => {
        state.isloadingComment = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.isloadingComment = false;
        state.isSuccess = true;
        state.isError = false;
        state.comments = state.comments.concat({...action?.payload?.body});
      })
      .addCase(addComment.rejected, (state, action) => {
        state.isloadingComment = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getComment.pending, (state) => {
        state.isloadingDetail = true;
      })
      .addCase(getComment.fulfilled, (state, action) => {
        state.isloadingDetail = false;
        state.isSuccess = true;
        state.isError = false;
        state.comments = action?.payload?.body;
      })
      .addCase(getComment.rejected, (state, action) => {
        state.isloadingDetail = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteComment.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.comments = state.comments.filter(
          (comment) => comment._id !== action?.payload?.body?._id
        );
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateComment.pending, (state) => {
        state.isloadingComment = true;
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.isloadingComment = false;
        state.isSuccess = true;
        state.comments = state.comments.map((comment) =>
          comment._id === action?.payload?.body?._id
            ? { ...action?.payload?.body}
            : comment
        );
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.isloadingComment = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taskTimeLogHistoryGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(taskTimeLogHistoryGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.timeLogHistory = action?.payload?.body;
      })
      .addCase(taskTimeLogHistoryGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(usertaskTimeLogGet.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(usertaskTimeLogGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.userTimeLog = action?.payload?.body;
      })
      .addCase(usertaskTimeLogGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taskTimeLog.pending, (state) => {
        state.isloadingTimeLog = true;
      })
      .addCase(taskTimeLog.fulfilled, (state, action) => {
        state.isloadingTimeLog = false;
        state.isSuccess = true;
        state.sections = state.sections.map((section) =>
          section._id === action?.payload?.body?.section
            ? {
                ...section,
                task: section.task.map((task) =>
                  task._id === action.payload.body._id ? { ...task, ...action.payload.body} : task
                ),
              }
            : section
        );
      })
      .addCase(taskTimeLog.rejected, (state, action) => {
        state.isloadingTimeLog = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      }) 

      .addCase(checkoutTaskTimeLogUpdate.pending, (state) => {
        state.isloadingTimeLog = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(checkoutTaskTimeLogUpdate.fulfilled, (state, action) => {
        state.isloadingTimeLog = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(checkoutTaskTimeLogUpdate.rejected, (state, action) => {
        state.isloadingTimeLog = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      }) 
      
  },
});
export const { resets } = kanbanSlice.actions;

export default kanbanSlice.reducer;
