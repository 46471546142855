import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addCategory = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };    
  const response = await axios.post(
    `${BASE_URL}/admin/category/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Category api failed"); 
};

const userCategory = async (token,pageno,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };     
    const response = await axios.get(
      `${BASE_URL}/admin/user/category?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Category List api failed"); 
  };

  const updateCategory = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.put(
      `${BASE_URL}/admin/category/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Category api failed"); 
  };

  const activeCategory = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };     
    const response = await axios.get(
      `${BASE_URL}/admin/user/category/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Category api failed"); 
  };

  const allCategory = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.get(
      `${BASE_URL}/superAdmin/category/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Category List api failed"); 
  };

const categoryServices = {
    addCategory,
    userCategory,
    updateCategory,
    activeCategory,
    allCategory
};

export default categoryServices;
