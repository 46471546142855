import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const login = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/auth/login`,
    data,
  );
  if (response?.data) {
    return response.data;
  }
  throw new Error("Login api failed");
};

const register = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/auth/signup`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Register api failed");
};

const forgotPassword = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/forgotpassword`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ForgotPassword api failed");
};

const verifyOTP = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/validatepin`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ValidatePin api failed");
};

const resetpassword = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/resetpassword`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ResetPassword api failed");
};

const searchContacts = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/auth/search?search=${data.search}`,
    config
  );
  if (response?.data) {
    return response.data;
  }

  throw new Error("Add Business api failed");
};

const userProfileUpdate = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/auth/update/profile`,
     data,
    config,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Update User Profile api failed");
};

const changePassword = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/auth/changepassword`,
     data,
    config,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Change Password api failed");
};

const verifyAccountOTP = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/verifyAccountOTP`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("verifyAccount OTP api failed");
};


const authService = {
  login,
  register,
  forgotPassword,
  verifyOTP,
  resetpassword,
  searchContacts,
  userProfileUpdate,
  changePassword,
  verifyAccountOTP
};

export default authService;
