import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import {combineReducers } from "redux";
import {thunk} from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./features/auth/authSlice";
import { countrySlice } from "./features/country/countrySlice";
import { businessSlice } from "./features/business/businessSlice";
import { categorySlice } from "./features/category/categorySlice";
import { branchSlice } from "./features/branch/branchSlice";
import { serviceSlice } from "./features/service/serviceSlice";
import { userSlice } from "./features/user/userSlice";
import { roleSlice } from "./features/role/roleSlice";
import { attendanceSlice } from "./features/attendance/attendanceSlice";
import { payrolSlice } from "./features/payrole/payroleSlice";
import { projectSlice } from "./features/project/projectSlice";
import { dashboardSlice } from "./features/dashboard/dashboardSlice";
import { invoiceSlice } from "./features/invoice/invoiceSlice";
import { kanbanSlice } from "./features/kanban/kanbanSlice";
import { businessTypeSlice } from "./features/businessType/businessTypeSlice";
import { leaveSlice } from "./features/leave/leaveSlice";
import {activitySlice} from "./features/activity/activitySlice";
import {uploadFileSlice} from "./features/uploadFile/uploadFileSlice";
import { chatSlice } from "./features/chats/chatSlice";
import {blogSlice} from "./features/blog/blogSlice";
import {boardSlice} from "./features/board/boardSlice";
import {moduleSlice} from "./features/module/moduleSlice";
import {versionSlice} from "./features/version/versionSlice";
import {projectVersionSlice} from './features/projectVersion/projectVersionSlice';
import {courseSlice} from './features/course/courseSlice';
import {blogCategorySlice} from './features/blogCategory/blogCategorySlice';


const rootReducer = combineReducers({
  auth:authSlice.reducer,
  country:countrySlice.reducer,
  business:businessSlice.reducer,
  category:categorySlice.reducer,
  branch:branchSlice.reducer,
  service:serviceSlice.reducer,
  user:userSlice.reducer,
  role:roleSlice.reducer,
  attendance:attendanceSlice.reducer,
  payrol:payrolSlice.reducer,
  project:projectSlice.reducer,
  dashboard:dashboardSlice.reducer,
  invoice:invoiceSlice.reducer,
  kanban:kanbanSlice.reducer,
  businessType:businessTypeSlice.reducer,
  leave: leaveSlice.reducer,
  uploadFile:uploadFileSlice.reducer,
  activity:activitySlice.reducer,
  conversations: chatSlice.reducer,
  blogs:blogSlice.reducer,
  boards:boardSlice.reducer,
  modules:moduleSlice.reducer, 
  versions:versionSlice.reducer,
  projectVersion:projectVersionSlice.reducer,
  course:courseSlice.reducer,
  blogCategory:blogCategorySlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk), 
});

export const persistor = persistStore(store);
