import axios from "src/utils/useAxios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const getAllCountry = async () => {
    const response = await axios.get(
      `${BASE_URL}/country`
    );
    if (response.data.data) {
      return response.data;
    }
    throw new Error("Country api failed"); 
  };


const getCountry = async () => {
    const response = await axios.get(
      `${BASE_URL}/country/allCountry`
    );
    if (response.data.data) {
      return response.data;
    }
    throw new Error("Country api failed"); 
  };

const countryService = {
    getAllCountry,
    getCountry
};

export default countryService;