import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatServices from "./chatServices";

const initialState = {
  conversations: [],
  conversationAttachment: [],
  isLoadingAddGroup:false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const createConversation = createAsyncThunk(
  "createConversation",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.createConversation(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllConversation = createAsyncThunk(
  "getAllConversation",
  async (limit,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.allConversation(user.accesstoken,limit);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getConversationById = createAsyncThunk(
  "getConversationById",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.getConversationById(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createGroup = createAsyncThunk(
  "createGroup",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.createGroup(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addGroupMember = createAsyncThunk(
  "addGroupMember",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.addGroupMember(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getConversationAttachmentById = createAsyncThunk(
  "getConversationAttachmentById",
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.getConversationAttachmentById(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const markAllMessagesAsRead = createAsyncThunk(
  "markAllMessagesAsRead",
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.markAllMessagesAsRead(user.accesstoken,id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const chatSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isLoadingAddGroup=false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.createdConversation = action.payload
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getAllConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allConversations = action.payload
      })
      .addCase(getAllConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getConversationById.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getConversationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getConversationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(createGroup.pending, (state) => {
        state.isLoadingAddGroup = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.isLoadingAddGroup = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.isLoadingAddGroup = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addGroupMember.pending, (state) => {
        state.isLoadingAddGroup = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(addGroupMember.fulfilled, (state, action) => {
        state.isLoadingAddGroup = false;
        state.isSuccess = true;
        state.isError = false;
        state.createdConversation = action.payload
      })
      .addCase(addGroupMember.rejected, (state, action) => {
        state.isLoadingAddGroup = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getConversationAttachmentById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getConversationAttachmentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.conversationAttachment = action?.payload?.body
      })
      .addCase(getConversationAttachmentById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(markAllMessagesAsRead.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(markAllMessagesAsRead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(markAllMessagesAsRead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});
export const { resets } = chatSlice.actions;

export default chatSlice.reducer;
