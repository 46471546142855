import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addBusinessType = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/superAdmin/businessType/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Business Type api failed"); 
};

const businessType = async (token,pageno,limit) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/superAdmin/businessType?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Business Type List api failed"); 
  };

  const updateBusinessType = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/superAdmin/businessType/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Business Type api failed"); 
  };

  const activeBusinessType = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/superAdmin/businessType/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Business Type api failed"); 
  };

const businessTypeServices = {
    addBusinessType,
    businessType,
    updateBusinessType,
    activeBusinessType,
};

export default businessTypeServices;
