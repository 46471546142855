import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import invoiceServices from "./invoiceServices";

const initialState = {
  invoices:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewInvoice = createAsyncThunk(
    "addNewInvoice",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await invoiceServices.addInvoice(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserInvoice = createAsyncThunk(
    "getUserInvoice",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await invoiceServices.userInvoice(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateInvoice = createAsyncThunk(
    "updateInvoice",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await invoiceServices.updateInvoice(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const deleteInvoice = createAsyncThunk(
    "deleteInvoice",
    async (id, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await invoiceServices.deleteInvoice(user.accesstoken,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
   
  export const allInvoice = createAsyncThunk(
    "allInvoice",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await invoiceServices.allInvoice(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  


export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getUserInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.invoices = [];
      })
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = state.invoices.filter((invoice) => invoice._id !== action?.payload?.body?._id);
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(allInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.invoices =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
  },
});
export const { resets } = invoiceSlice.actions;

export default invoiceSlice.reducer;