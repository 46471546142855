import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import branchServices from "./branchServices";

const initialState = {
  branches:[],
  activeBranch:[],
  businessBranch:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewBranch = createAsyncThunk(
    "addNewBranch",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.addBranch(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserBranch = createAsyncThunk(
    "getUserBranch",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.userBranch(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateBranch = createAsyncThunk(
    "updateBranch",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.updateBranch(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getBusinessBranch = createAsyncThunk(
    "getBusinessBranch",
    async (business, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.getBusinessBranch(user.accesstoken,business);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const activeBranchList = createAsyncThunk(
    "activeBranchList",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.activeBranch(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  
  export const allBranch = createAsyncThunk(
    "allBranch",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await branchServices.allBranch(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.businessBranch=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserBranch.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.branches =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getUserBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.branches = [];
      })
      .addCase(updateBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeBranchList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeBranchList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeBranch=action?.payload?.body;
      })
      .addCase(activeBranchList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allBranch.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.branches =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(allBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.branches = [];
      })
      .addCase(getBusinessBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.businessBranch =action?.payload?.body;
      })
      .addCase(getBusinessBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessBranch = [];
      })
  },
});
export const { resets } = branchSlice.actions;

export default branchSlice.reducer;
