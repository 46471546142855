import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const getAllRole = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/superAdmin/role/all`,
      config
    );
    if (response.data) {
      return response.data;
    }
    throw new Error("Role api failed"); 
  };

const roleService = {
    getAllRole
};

export default roleService;
