// utils
import { paramCase,snakeCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/admin',
  SuperAdmin:'/superAdmin'
};

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  privacyPolicy:'privacy-policy',
  termsAndCondition:'terms-and-condition',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  blog: {
    root: `/blog`,
    details: (title) => `/blog/${title}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/login`,
      register: `/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `/forgot-password`,
      newPassword: `/new-password`,
      verify: `/verify`,
      verifyAccount: `/verify-account`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    dashboard: `${ROOTS.DASHBOARD}/dashboard`,
    business: `${ROOTS.DASHBOARD}/business`,
    newBusiness: `${ROOTS.DASHBOARD}/business/new`,
    editBusiness: (id) => `${ROOTS.DASHBOARD}/business/${id}/edit`,
    category: `${ROOTS.DASHBOARD}/category`,
    newCategory: `${ROOTS.DASHBOARD}/category/new`,
    editCategory: (id) => `${ROOTS.DASHBOARD}/category/${id}/edit`,
    service: `${ROOTS.DASHBOARD}/service`,
    newService: `${ROOTS.DASHBOARD}/service/new`,
    editService: (id) => `${ROOTS.DASHBOARD}/service/${id}/edit`,
    projects: `${ROOTS.DASHBOARD}/projects`,
    newProject: `${ROOTS.DASHBOARD}/projects/new`,
    editProject: (id) => `${ROOTS.DASHBOARD}/projects/${id}/edit`,
    detailProject: (id) => `${ROOTS.DASHBOARD}/projects/${id}/detail`,
    module: `${ROOTS.DASHBOARD}/projects/module`,
    newModule: `${ROOTS.DASHBOARD}/projects/module/new`,
    editModule: (id) => `${ROOTS.DASHBOARD}/projects/module/${id}/edit`,
    version: `${ROOTS.DASHBOARD}/projects/version`,
    newVersion: `${ROOTS.DASHBOARD}/projects/version/new`,
    editVersion: (id) => `${ROOTS.DASHBOARD}/projects/version/${id}/edit`,
    payrole: `${ROOTS.DASHBOARD}/payroll`,
    newPayrole: `${ROOTS.DASHBOARD}/payroll/new`,
    editPayrole: (id) => `${ROOTS.DASHBOARD}/payroll/${id}/edit`,
    attendance: `${ROOTS.DASHBOARD}/attendance`,
    branch: `${ROOTS.DASHBOARD}/branch`,
    newBranch: `${ROOTS.DASHBOARD}/branch/new`,
    editBranch: (id) => `${ROOTS.DASHBOARD}/branch/${id}/edit`,
    userAttendence:(id,businessId) => `${ROOTS.DASHBOARD}/userAttendance/${id}/${businessId}`,
    leave:`${ROOTS.DASHBOARD}/leave`,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    blog: `${ROOTS.DASHBOARD}/blog`,
    newBlog: `${ROOTS.DASHBOARD}/blog/new`,
    editBlog: (id) => `${ROOTS.DASHBOARD}/blog/${id}/edit`,
    detailBlog: (title) => `${ROOTS.DASHBOARD}/blog/${title}`,
    kanban:(board,name,project) => `${ROOTS.DASHBOARD}/kanban/${board}/${paramCase(name)}/${project}`,
    board: `${ROOTS.DASHBOARD}/projects/board`,
    course:`${ROOTS.DASHBOARD}/courses`,
    newCourse: `${ROOTS.DASHBOARD}/courses/new`,
    manageCourse: (id,name) => `${ROOTS.DASHBOARD}/courses/${id}/manage/${name}`,
    detailCourse: (id,name) => `${ROOTS.DASHBOARD}/courses/${id}/detail/${name}`,
    activity: `${ROOTS.DASHBOARD}/activity`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile:(id,businessId) => `${ROOTS.DASHBOARD}/user/profile/${id}/${businessId}`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },


  superAdmin: {
    root: `${ROOTS.SuperAdmin}`,
    dashboard: `${ROOTS.SuperAdmin}/dashboard`,
    business: `${ROOTS.SuperAdmin}/business`,
    newBusiness: `${ROOTS.SuperAdmin}/business/new`,
    editBusiness: (id) => `${ROOTS.SuperAdmin}/business/${id}/edit`,
    businessType: `${ROOTS.SuperAdmin}/businessType`,
    newBusinessType: `${ROOTS.SuperAdmin}/businessType/new`,
    editBusinessType: (id) => `${ROOTS.SuperAdmin}/businessType/${id}/edit`,
    blogCategory: `${ROOTS.SuperAdmin}/blogCategory`,
    newBlogCategory: `${ROOTS.SuperAdmin}/blogCategory/new`,
    editBlogCategory: (id) => `${ROOTS.SuperAdmin}/blogCategory/${id}/edit`,
    category: `${ROOTS.SuperAdmin}/category`,
    newCategory: `${ROOTS.SuperAdmin}/category/new`,
    editCategory: (id) => `${ROOTS.SuperAdmin}/category/${id}/edit`,
    service: `${ROOTS.SuperAdmin}/service`,
    newService: `${ROOTS.SuperAdmin}/service/new`,
    editService: (id) => `${ROOTS.SuperAdmin}/service/${id}/edit`,
    projects: `${ROOTS.SuperAdmin}/projects`,
    newProject: `${ROOTS.SuperAdmin}/projects/new`,
    editProject: (id) => `${ROOTS.SuperAdmin}/projects/${id}/edit`,
    payrole: `${ROOTS.SuperAdmin}/payrole`,
    newPayrole: `${ROOTS.SuperAdmin}/payrole/new`,
    editPayrole: (id) => `${ROOTS.SuperAdmin}/payrole/${id}/edit`,
    attendance: `${ROOTS.SuperAdmin}/attendance`,
    branch: `${ROOTS.SuperAdmin}/branch`,
    newBranch: `${ROOTS.SuperAdmin}/branch/new`,
    editBranch: (id) => `${ROOTS.SuperAdmin}/branch/${id}/edit`,
    blog: `${ROOTS.SuperAdmin}/blog`,
    newBlog: `${ROOTS.SuperAdmin}/blog/new`,
    editBlog: (id) => `${ROOTS.SuperAdmin}/blog/${id}/edit`,
    detailBlog: (title) => `${ROOTS.SuperAdmin}/blog/${title}`,
    course:`${ROOTS.SuperAdmin}/courses`,
    manageCourse: (id,name) => `${ROOTS.SuperAdmin}/courses/${id}/manage/${name}`,
    mail: `${ROOTS.SuperAdmin}/mail`,
    chat: `${ROOTS.SuperAdmin}/chat`,
    blank: `${ROOTS.SuperAdmin}/blank`,
    kanban: `${ROOTS.SuperAdmin}/kanban`,
    calendar: `${ROOTS.SuperAdmin}/calendar`,
    user: {
      root: `${ROOTS.SuperAdmin}/user`,
      new: `${ROOTS.SuperAdmin}/user/new`,
      list: `${ROOTS.SuperAdmin}/user/list`,
      cards: `${ROOTS.SuperAdmin}/user/cards`,
      profile: `${ROOTS.SuperAdmin}/user/profile`,
      account: `${ROOTS.SuperAdmin}/user/account`,
      edit: (id) => `${ROOTS.SuperAdmin}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.SuperAdmin}/user/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.SuperAdmin}/invoice`,
      new: `${ROOTS.SuperAdmin}/invoice/new`,
      details: (id) => `${ROOTS.SuperAdmin}/invoice/${id}`,
      edit: (id) => `${ROOTS.SuperAdmin}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.SuperAdmin}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.SuperAdmin}/invoice/${MOCK_ID}/edit`,
      },
    }, 
  },
};
