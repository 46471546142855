import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';


// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));

const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));

const ProjectListPage = lazy(() => import('src/pages/dashboard/project/list'));
const ProjectAdd = lazy(() => import('src/pages/dashboard/project/add'));
const ProjectEdit = lazy(() => import('src/pages/dashboard/project/edit'));


const BusinessTypeListPage = lazy(() => import('src/pages/dashboard/businessType/list'));
const BusinessTypeAdd = lazy(() => import('src/pages/dashboard/businessType/add'));
const BusinessTypeEdit = lazy(() => import('src/pages/dashboard/businessType/edit'));

// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/blog/add'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/blog/edit'));
const BlogDetialPage = lazy(() => import('src/pages/dashboard/blog/detail'));
// BLOG Category
const BlogCategoryList = lazy(() => import('src/pages/dashboard/blogCategory/list'));
const BlogCategoryAdd = lazy(() => import('src/pages/dashboard/blogCategory/add'));
const BlogCategoryEdit = lazy(() => import('src/pages/dashboard/blogCategory/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
// const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
// const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
// const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
// const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

const BusinessPage = lazy(() => import('src/pages/dashboard/business'));
// const CategoryPage = lazy(() => import('src/pages/dashboard/category'));
const ServicePage = lazy(() => import('src/pages/dashboard/service/list'));
const ServiceAdd = lazy(() => import('src/pages/dashboard/service/add'));
const ServiceEdit = lazy(() => import('src/pages/dashboard/service/edit'));

// Business
const BusinessListPage = lazy(() => import('src/pages/dashboard/business/list'));
const BusinessAdd = lazy(() => import ( 'src/pages/dashboard/business/add'));
const BusinessEdit = lazy(() => import ( 'src/pages/dashboard/business/edit'));

// Category
const CategoryListPage = lazy(() => import('src/pages/dashboard/category/list'));
const CategoryAdd = lazy(() => import( 'src/pages/dashboard/category/add'));
const CategoryEdit = lazy(() => import( 'src/pages/dashboard/category/edit'));


// Branch
const BranchListPage = lazy(() => import('src/pages/dashboard/branch/list'));
const BranchCreateView = lazy(() => import('src/sections/branch/view/branch-create-view'));
const BranchEdit = lazy(() => import('src/pages/dashboard/branch/edit'));

// Attendance
const AttendanceListPage = lazy(() => import('src/pages/dashboard/attendance/list'));
const AttendanceDetailsPage = lazy(() => import('src/pages/dashboard/attendance/details'));


// Course
const CourseListPage = lazy(() => import( 'src/pages/dashboard/course/list'));
const CourseManage = lazy(() => import( 'src/pages/dashboard/course/manage'));  

// Payrole
const PayroleListPage = lazy(() => import('src/pages/dashboard/payrole/list'));
const PayroleAdd = lazy(() => import('src/pages/dashboard/payrole/add'));
const PayroleEdit = lazy(() => import('src/pages/dashboard/payrole/edit'));
// ----------------------------------------------------------------------

export const superAdminRoutes = [
  {
    path: 'superAdmin',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path :'dashboard' ,element: <IndexPage />, index: true },
      { path: 'projects',
      children: [
        { element: <ProjectListPage />, index: true },
        { path: 'new', element: <ProjectAdd/> },
        { path: ':id/edit', element: <ProjectEdit /> },  
      ],
    },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'service',
      children: [
        { element: <ServicePage />, index: true },
        { path: 'new', element: <ServiceAdd/> },
        { path: ':id/edit', element: <ServiceEdit /> },  
      ],
    
    },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'business',
        children: [
          { element: <BusinessListPage />, index: true },
          { path: 'list', element: <BusinessListPage /> },
          { path: 'new', element: <BusinessAdd/> },
          { path: ':id/edit', element: <BusinessEdit /> },
  
        ],
      },
      {
        path: 'businessType',
        children: [
          { element: <BusinessTypeListPage />, index: true },
          { path: 'list', element: <BusinessTypeListPage /> },
          { path: 'new', element: <BusinessTypeAdd/> },
          { path: ':id/edit', element: <BusinessTypeEdit /> },
  
        ],
      },
      {
        path: 'category',
        children: [
          { element: <CategoryListPage />, index: true },
          { path: 'list', element: <CategoryListPage /> },
          { path: 'new', element: <CategoryAdd/> },
          { path: ':id/edit', element: <CategoryEdit /> },
        ],
      },
      {
        path: 'branch',
        children: [
          { element: <BranchListPage />, index: true },
          { path: 'list', element: <BranchListPage /> },
          { path: 'new', element: <BranchCreateView /> },
          { path: ':id/edit', element: <BranchEdit /> },
        ],
      },
      {
        path: 'attendance',
        children: [
          { element: <AttendanceListPage />, index: true },
          { path: 'list', element: <AttendanceListPage /> },
          { path: ':id', element: <AttendanceDetailsPage /> },
        ],
      },
      {
        path: 'payrole',
        children: [
          { element: <PayroleListPage />, index: true },
          { path: 'list', element: <PayroleListPage /> },
          { path: 'new', element: <PayroleAdd /> },
          { path: ':id/edit', element: <PayroleEdit /> }, 
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'blog',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: 'new', element: <BlogNewPostPage/> },
          { path: ':id/edit', element: <BlogEditPostPage /> },
          { path: ':title', element: <BlogDetialPage /> },
        ],
      },
      {
        path: 'blogCategory',
        children: [
          { element: <BlogCategoryList />, index: true },
          { path: 'list', element: <BlogCategoryList /> },
          { path: 'new', element: <BlogCategoryAdd/> },
          { path: ':id/edit', element: <BlogCategoryEdit /> },
  
        ],
      },
      {
        path: 'courses',
        children: [
          { element: <CourseListPage />, index: true },
          { path: 'list', element: <CourseListPage /> },
          { path: ':id/manage/:name', element: <CourseManage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
