import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addService = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };     
  const response = await axios.post(
    `${BASE_URL}/admin/service/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Service api failed"); 
};

const userService = async (token,pageno,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };       
    const response = await axios.get(
      `${BASE_URL}/admin/user/service?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Service List api failed"); 
  };

  const updateService = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.put(
      `${BASE_URL}/admin/service/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Service Branch api failed"); 
  };

  const activeService = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };        
    const response = await axios.get(
      `${BASE_URL}/admin/user/service/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Service api failed"); 
  };
  
  const allService = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };          
    const response = await axios.get(
      `${BASE_URL}/superAdmin/service/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Service List api failed"); 
  };

  const businessAganistService = async (token,businessId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };        
    const response = await axios.get(
      `${BASE_URL}/admin/service/against/${businessId}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Business Against Service api failed"); 
  };


const serviceServices = {
    addService,
    userService,
    updateService,
    activeService,
    allService,
    businessAganistService
};

export default serviceServices;