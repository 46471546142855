import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryServices from "./categoryServices";

const initialState = {
  categories:[],
  activeCategory:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewCategory = createAsyncThunk(
    "addNewCategory",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await categoryServices.addCategory(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserCategory = createAsyncThunk(
    "getUserCategory",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await categoryServices.userCategory(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateCategory = createAsyncThunk(
    "updateCategory",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await categoryServices.updateCategory(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getActiveCategory = createAsyncThunk(
    "getActiveCategory",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await categoryServices.activeCategory(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
   
  export const allCategory = createAsyncThunk(
    "allCategory",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await categoryServices.allCategory(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  


export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCategory.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.categories =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getUserCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getActiveCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeCategory=action?.payload?.body;
      })
      .addCase(getActiveCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allCategory.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.categories =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(allCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      })
  },
});
export const { resets } = categorySlice.actions;

export default categorySlice.reducer;