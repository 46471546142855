
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addVersion = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/version/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Version api failed"); 
};

const getBusinessVersion = async (token,business,pageno,perPage,search) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/admin/version/${business}?page=${pageno}&perPage=${perPage}&search=${search ?? ''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Version List api failed"); 
  };

  const updateVersion = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/version/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Version api failed"); 
  };

  const updateStatusVersion = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/version/status/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Version api failed"); 
  };
  const activeVersion = async (token,bussiness) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/active/version/${bussiness}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Version api failed"); 
  };

const versionServices = {
    addVersion,
    getBusinessVersion,
    updateVersion,
    updateStatusVersion,
    activeVersion
};

export default versionServices;
