import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogServices from "./blogServices";

const initialState = {
  blogs:[],
  searchBlogs:[],
  publishBlog:[],
  searchPublishBlogs:[],
  recentBlogs:[],
  singleBlog:{},
  totalRecord:0,
  totalPages:0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingSearch: false,
  message: "",
};


  export const addNewBlog = createAsyncThunk(
    "addNewBlog",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.addBlog(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getBlog = createAsyncThunk(
    "getBlog",
    async ({pageno},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.getBlog(user.accesstoken,pageno);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const updateBlog = createAsyncThunk(
    "updateBlog",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.updateBlog(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const publicBlog = createAsyncThunk(
    "publicBlog",
    async (thunkAPI) => {
      try {
        return await blogServices.publicBlog();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const deleteBlog = createAsyncThunk(
    "deleteBlog",
    async ({id,data}, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.deleteBlog(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );  
  export const isPublishRequestBlog = createAsyncThunk(
    "isPublishRequestBlog",
    async ({pageno},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.isPublishRequestBlog(user.accesstoken,pageno);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getSeacrhBlog = createAsyncThunk(
    "getSeacrhBlog",
    async (search,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.getSearchBlog(user.accesstoken,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const  toggleisPublishBlog = createAsyncThunk(
    "toggleisPublishBlog",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices. toggleisPublishBlog(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const searchPublishBlogGet = createAsyncThunk(
    "searchPublishBlogGet",
    async (search,thunkAPI) => {
      try {
        return await blogServices.searchPublishBlogGet(search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getSuperAdminSearchBlog = createAsyncThunk(
    "getSuperAdminSearchBlog",
    async (search,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await blogServices.getSuperAdminSearchBlog(user.accesstoken,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getSingleBlog = createAsyncThunk(
    "getSingleBlog",
    async (id,thunkAPI) => {
      try {
        return await blogServices.getSingleBlog(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const recentBlog = createAsyncThunk(
    "recentBlog",
    async (thunkAPI) => {
      try {
        return await blogServices.recentBlog();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.isLoadingSearch=false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addNewBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.blogs =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.totalPages =action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(getBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogs = [];
      })
      .addCase(updateBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = state.blogs.filter((blog) => blog._id !== action?.payload?.body?._id);
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(publicBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(publicBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.publishBlog =action?.payload?.body;
      })
      .addCase(publicBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.publishBlog = [];
      })
      .addCase(getSeacrhBlog.pending, (state) => {
        state.isLoadingSearch = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSeacrhBlog.fulfilled, (state, action) => {
        state.isSuccess = false;
        state.searchBlogs =action?.payload?.body;
        state.isLoadingSearch = false;
      })
      .addCase(getSeacrhBlog.rejected, (state, action) => {
        state.isLoadingSearch = false;
        state.isError = true;
        state.message = action.payload;
        state.searchBlogs = [];
      })
      .addCase(searchPublishBlogGet.pending, (state) => {
        state.isLoadingSearch = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(searchPublishBlogGet.fulfilled, (state, action) => {
        state.isSuccess = false;
        state.searchPublishBlogs =action?.payload?.body;
        state.isLoadingSearch = false;
      })
      .addCase(searchPublishBlogGet.rejected, (state, action) => {
        state.isLoadingSearch = false;
        state.isError = true;
        state.message = action.payload;
        state.searchPublishBlogs = [];
      })
      .addCase(getSuperAdminSearchBlog.pending, (state) => {
        state.isLoadingSearch = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSuperAdminSearchBlog.fulfilled, (state, action) => {
        state.isSuccess = false;
        state.searchBlogs =action?.payload?.body;
        state.isLoadingSearch = false;
      })
      .addCase(getSuperAdminSearchBlog.rejected, (state, action) => {
        state.isLoadingSearch = false;
        state.isError = true;
        state.message = action.payload;
        state.searchBlogs = [];
      })
      .addCase(isPublishRequestBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(isPublishRequestBlog.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.blogs =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.totalPages =action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(isPublishRequestBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogs = [];
      })
      .addCase(toggleisPublishBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(toggleisPublishBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = state.blogs.map((board) =>
          board?._id === action.payload.body._id ? {...board,status:action.payload.body.status} : board
        );
      })
      .addCase(toggleisPublishBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSingleBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSingleBlog.fulfilled, (state, action) => {
        state.singleBlog =action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(getSingleBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.singleBlog = {};
      })
      .addCase(recentBlog.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(recentBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.recentBlogs=action?.payload?.body;
      })
      .addCase(recentBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});
export const { resets } = blogSlice.actions;

export default blogSlice.reducer;
