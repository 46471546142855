import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const getAdminDashboard = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };  
    const response = await axios.get(
      `${BASE_URL}/admin/dashboard`,
      config
    );
    if (response.data) {
      return response.data;
    }
    throw new Error("Admin Dashboard api failed"); 
};
const getSuperAdminDashboard = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/superAdmin/dashboard`,
      config
    );
    if (response.data) {
      return response.data;
    }
    throw new Error("SuperAdmin Dashboard api failed"); 
};
const productiveHoursGet = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
      const response = await axios.get(
        `${BASE_URL}/admin/productiveHours`,
        config
      );
      if (response.data) {
        return response.data;
      }
      throw new Error("Productive Hours api failed"); 
};
const totalWorkingHourGet = async (token) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };  
        const response = await axios.get(
          `${BASE_URL}/admin/totalWorkingHour`,
          config
        );
        if (response.data) {
          return response.data;
        }
        throw new Error("Total Working Hours api failed"); 
};
const userDashboardAganistBusinessGet = async (token,user,business) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/dashboard/${user}/${business}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('User Dashboard Agnist Business api failed');
};
const userProductiveAganistBusinessGet = async (token,user,business,fromDate,toDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/productiveHours/${user}/${business}?fromDate=${fromDate ?? ''}&toDate=${
      toDate ?? ''
    }`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('User Productive Hours Agnist Business api failed');
};


const dashboardServices = {
    getAdminDashboard,
    getSuperAdminDashboard,
    productiveHoursGet,
    totalWorkingHourGet,
    userDashboardAganistBusinessGet,
    userProductiveAganistBusinessGet 
};

export default dashboardServices;
