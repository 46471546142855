import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import leaveServices from "./leaveServices";

const initialState = {
  leaves:[],
  leavesStatistics:{},
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingButton: false,
  message: "",
};


export const addNewLeave = createAsyncThunk(
    "addNewLeave",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.addLeave(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
  
export const getUserLeave = createAsyncThunk(
    "getUserLeave",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.userleave(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getUserEmployeeLeave = createAsyncThunk(
    "getUserEmployeeLeave",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.userEmployeeLeave(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateLeaveStatus = createAsyncThunk(
    "updateLeaveStatus",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.updateleaveStatus(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const userStatisticsleave = createAsyncThunk(
    "userStatisticsleave",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.userStatisticsleave(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const empolyeeStatisticsleave = createAsyncThunk(
    "empolyeeStatisticsleave",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await leaveServices.empolyeeStatisticsleave(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isLoadingButton=false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewLeave.pending, (state) => {
        state.isLoadingButton = true;
        state.isSuccess=false;
        state.isError=false;
      })
      .addCase(addNewLeave.fulfilled, (state, action) => {
        state.isLoadingButton = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewLeave.rejected, (state, action) => {
        state.isLoadingButton = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserLeave.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserLeave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.leaves =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getUserLeave.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.leaves = [];
      })
      .addCase(getUserEmployeeLeave.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserEmployeeLeave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.leaves =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getUserEmployeeLeave.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.leaves = [];
      })
      .addCase(updateLeaveStatus.pending, (state) => {
        state.isLoadingButton = true;
      })
      .addCase(updateLeaveStatus.fulfilled, (state, action) => {
        state.isLoadingButton = false;
        state.isSuccess = true;
        state.leaves =state.leaves.map((leave) =>
            leave._id === action.payload.body._id ? action.payload.body : leave
          );
      })
      .addCase(updateLeaveStatus.rejected, (state, action) => {
        state.isLoadingButton = false;
        state.isError = true;
        state.message = action.payload;
        state.leaves = [];
      })
      .addCase(userStatisticsleave.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userStatisticsleave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.leavesStatistics =action?.payload?.body;
      })
      .addCase(userStatisticsleave.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.leavesStatistics = {};
      })
      .addCase(empolyeeStatisticsleave.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(empolyeeStatisticsleave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.leavesStatistics =action?.payload?.body;
      })
      .addCase(empolyeeStatisticsleave.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.leavesStatistics = {};
      }) 
  },
});
export const { resets } = leaveSlice.actions;

export default leaveSlice.reducer;