import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addInvoice = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/admin/invoice/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Invoice api failed"); 
};

const userInvoice = async (token,pageno,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };     
    const response = await axios.get(
      `${BASE_URL}/admin/user/invoice?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Invoice List api failed"); 
  };

  const updateInvoice = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };     
    const response = await axios.put(
      `${BASE_URL}/admin/invoice/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Invoice Payrol api failed"); 
  };

  const deleteInvoice = async (token,id) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };     
    const response = await axios.delete(
      `${BASE_URL}/admin/invoice/${id}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Delete Invoice api failed"); 
  };

  const allInvoice = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.get(
      `${BASE_URL}/superAdmin/invoice/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Invoice List api failed"); 
  };

const invoiceServices = {
    addInvoice,
    userInvoice,
    updateInvoice,
    deleteInvoice,
    allInvoice
};

export default  invoiceServices;
