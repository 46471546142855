import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addBranch = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/admin/branch/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Branch api failed"); 
};

const userBranch = async (token,pageno,limit,search) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
    const response = await axios.get(
      `${BASE_URL}/admin/user/branch?page=${pageno}&perPage=${limit}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Branch List api failed"); 
  };

  const updateBranch = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.put(
      `${BASE_URL}/admin/branch/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Branch api failed"); 
  };

  const activeBranch = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };    
    const response = await axios.get(
      `${BASE_URL}/admin/user/branch/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Branch api failed"); 
  };

  const allBranch = async (token,pageno,limit) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/superAdmin/branch/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Branch List api failed"); 
  };

  const getBusinessBranch = async (token,business) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/branch/${business}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Business Branch api failed"); 
  };

const branchServices = {
    addBranch,
    userBranch,
    updateBranch,
    activeBranch,
    allBranch,
    getBusinessBranch
};

export default branchServices;