import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN,PATH_AFTER_Super_Admin} from 'src/config-global';

export default function LoginButton({sx}) {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  return (
    <Button component={RouterLink} href={ user?.role?.name ==='superadmin' ? PATH_AFTER_Super_Admin : PATH_AFTER_LOGIN} variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
