import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projrctServices from "./projectServices";

const initialState = {
  projects:[],
  activeProject:[],
  businessProject:[],
  singleProject:{},
  totalRecord:null,
  totalPages:0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingAssign:false,
  message: "",
};


export const addNewProject = createAsyncThunk(
    "addNewProject",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.addProject(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserProject = createAsyncThunk(
    "getUserProject",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.userProject(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateProject = createAsyncThunk(
    "updateProject",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.updateProject(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const  assignProject = createAsyncThunk(
    "assignProject",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.assignProject(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getSingleProject = createAsyncThunk(
    "getSingleProject",
    async (id, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.singleProject(user.accesstoken,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  
  export const activeProjectList = createAsyncThunk(
    "activeProjectList",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.activeProject(
          user.accesstoken
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allProject = createAsyncThunk(
    "allProject",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.allProject(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getBusinessProject = createAsyncThunk(
    "getBusinessProject",
    async (id, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await projrctServices.getBusinessProject(user.accesstoken,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.isLoadingAssign=false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addNewProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserProject.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.projects =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.totalPages=action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(getUserProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.projects = [];
      })
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(assignProject.pending, (state) => {
        state.isLoadingAssign = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(assignProject.fulfilled, (state, action) => {
        state.isLoadingAssign = false;
      })
      .addCase(assignProject.rejected, (state, action) => {
        state.isLoadingAssign = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeProjectList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(activeProjectList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeProject =action?.payload?.body;
      })
      .addCase(activeProjectList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeProject = [];
      })
      .addCase(allProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allProject.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.projects =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(allProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.projects = [];
      })
      .addCase(getSingleProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSingleProject.fulfilled, (state, action) => {
        state.singleProject =action?.payload?.body;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getSingleProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.singleProject ={};
        state.message = action.payload;
      })
      .addCase(getBusinessProject.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getBusinessProject.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessProject =action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(getBusinessProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.businessProject =[];
        state.message = action.payload;
      })
  },
});
export const { resets } = projectSlice.actions;

export default projectSlice.reducer;
