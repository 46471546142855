import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import versionServices from './versionServices';

const initialState = {
  versions:[],
  activeVersion:[],
  totalRecord: 0,
  totalPages: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addNewVersion = createAsyncThunk('addNewVersion', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await versionServices.addVersion(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getBusinessVersion = createAsyncThunk(
  'getBusinessVersion',
  async ({business,pageno,perPage,search}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await versionServices.getBusinessVersion(user.accesstoken,business,pageno, perPage,search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateVersion = createAsyncThunk('updateVersion', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await versionServices.updateVersion(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateStatusVersion = createAsyncThunk('updateStatusVersion', async ({id,data} ,thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await versionServices.updateStatusVersion(user.accesstoken,id,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const activeVersionList = createAsyncThunk(
  "activeVersionList",
  async (bussiness,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await versionServices.activeVersion(
        user.accesstoken,bussiness
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBusinessVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessVersion.fulfilled, (state, action) => {
        state.versions = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.totalPages = action?.payload?.total_pages;
        state.isLoading = false;
      })
      .addCase(getBusinessVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.versions = [];
      })
      .addCase(updateVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateStatusVersion.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateStatusVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.versions = state.versions.map((version) =>
          version?._id === action.payload.body._id ? action.payload.body : version
          );
      })
      .addCase(updateStatusVersion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeVersionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeVersionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeVersion =action?.payload?.body;
      })
      .addCase(activeVersionList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeVersion = [];
      })
  },
});
export const { resets } = versionSlice.actions;

export default versionSlice.reducer;
