import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import businessTypeServices from "./businessTypeServices";

const initialState = {
  businessType:[],
  activeBusinessType:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewBusinessType = createAsyncThunk(
    "addNewBusinessType",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessTypeServices.addBusinessType(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getBusinessType = createAsyncThunk(
    "getBusinessType",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessTypeServices.businessType(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateBusinessType = createAsyncThunk(
    "updateBusinessType",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessTypeServices.updateBusinessType(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const activeBusinessTypeList = createAsyncThunk(
    "activeBusinessTypeList",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await businessTypeServices.activeBusinessType(
          user.accesstoken
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const businessTypeSlice = createSlice({
  name: "businessType",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBusinessType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewBusinessType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewBusinessType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBusinessType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessType.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessType =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getBusinessType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessType = [];
      })
      .addCase(updateBusinessType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusinessType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateBusinessType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeBusinessTypeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeBusinessTypeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeBusinessType =action?.payload?.body;
      })
      .addCase(activeBusinessTypeList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeBusinessType = [];
      })
  },
});
export const { resets } = businessTypeSlice.actions;

export default businessTypeSlice.reducer;
