import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardServices from "./dashboardServices";

const initialState = {
  dashboard:[],
  productiveHours:{},
  totalWorkingHour:{},
  userDashboardBusiness:{},
  userProductiveHours:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAdminDashboard = createAsyncThunk(
  "getAdminDashboard",
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await dashboardServices.getAdminDashboard(user.accesstoken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSuperAdminDashboard = createAsyncThunk(
    "getSuperAdminDashboard",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await dashboardServices.getSuperAdminDashboard(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const productiveHoursGet = createAsyncThunk(
    "productiveHoursGet",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await dashboardServices.productiveHoursGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const totalWorkingHourGet = createAsyncThunk(
    "totalWorkingHourGet",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await dashboardServices.totalWorkingHourGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const userDashboardAganistBusinessGet = createAsyncThunk(
    "userDashboardAganistBusinessGet",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await dashboardServices.userDashboardAganistBusinessGet(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const userProductiveAganistBusinessGet = createAsyncThunk(
  "userProductiveAganistBusinessGet",
  async ({business,userId,fromDate,toDate},thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await dashboardServices.userProductiveAganistBusinessGet(user.accesstoken,userId,business,fromDate,toDate);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminDashboard.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getAdminDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboard = action?.payload?.body;
      })
      .addCase(getAdminDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dashboard = {};
      })
      .addCase(getSuperAdminDashboard.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSuperAdminDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboard = action?.payload?.body;
      })
      .addCase(getSuperAdminDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dashboard = {};
      })
      .addCase(productiveHoursGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(productiveHoursGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.productiveHours = action?.payload?.body;
      })
      .addCase(productiveHoursGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.productiveHours = {};
      })
      .addCase(totalWorkingHourGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(totalWorkingHourGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.totalWorkingHour = action?.payload?.body;
      })
      .addCase(totalWorkingHourGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.totalWorkingHour = {};
      })
      .addCase(userDashboardAganistBusinessGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userDashboardAganistBusinessGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userDashboardBusiness = action?.payload?.body;
      })
      .addCase(userDashboardAganistBusinessGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userDashboardBusiness = {};
      })
      .addCase(userProductiveAganistBusinessGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userProductiveAganistBusinessGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userProductiveHours = action?.payload?.body;
      })
      .addCase(userProductiveAganistBusinessGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userProductiveHours = {};
      })
  },
});
export const { reset } = dashboardSlice.actions;

export default dashboardSlice.reducer;
