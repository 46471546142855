import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import payroleServices from "./payroleServices";

const initialState = {
  payrols:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewPayrol = createAsyncThunk(
    "addNewPayrol",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await payroleServices.addPayrol(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getUserPayrol = createAsyncThunk(
    "getUserPayrol",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await payroleServices.userPayrol(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updatePayrol = createAsyncThunk(
    "updatePayrol",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await payroleServices.updatePayrol(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const deletePayrol = createAsyncThunk(
    "deletePayrol",
    async (id, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await payroleServices.deletePayrol(user.accesstoken,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const allPayrol = createAsyncThunk(
    "allPayrol",
    async ({pageno,limit},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await payroleServices.allPayrol(user.accesstoken,pageno,limit);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const payrolSlice = createSlice({
  name: "payrol",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewPayrol.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewPayrol.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewPayrol.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserPayrol.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserPayrol.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payrols =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(getUserPayrol.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.payrols = [];
      })
      .addCase(updatePayrol.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePayrol.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatePayrol.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deletePayrol.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePayrol.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payrols = state.payrols.filter((payrol) => payrol._id !== action?.payload?.body?._id);
      })
      .addCase(deletePayrol.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allPayrol.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allPayrol.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payrols =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
      })
      .addCase(allPayrol.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.payrols = [];
      })
  },
});
export const { resets } = payrolSlice.actions;

export default payrolSlice.reducer;
