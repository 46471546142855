
import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

// Add Course API
const addCourse = async (token,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/course/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Course api failed"); 
  };
// Get Course API
const getCourse = async (token,pageno,perPage) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const response = await axios.get(
      `${BASE_URL}/admin/course?page=${pageno}&perPage=${perPage}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Course List api failed"); 
  };
// Update Course API   
const updateCourse = async (token,id,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.put(
      `${BASE_URL}/admin/course/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Course api failed"); 
  };
// Get Single Course API 
const getSingleCourse = async (token, courseId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/course/single/${courseId}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Single Course List api failed');
  };
 // Get Publish Course API 
const getPublishCourse = async (token,pageno,perPage) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/course/publish?page=${pageno}&perPage=${perPage}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Course Publish List api failed"); 
  };
// Get Enrolled Course API
  const enrolledCourseGet = async (token,pageno,perPage) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
      const response = await axios.get(
        `${BASE_URL}/admin/enrolled/course?page=${pageno}&perPage=${perPage}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Course Publish List api failed"); 
  };

  // Get Review Course API
const getReviewCourses = async (token,pageno,perPage) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/superAdmin/course/review?page=${pageno}&perPage=${perPage}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Review Course List api failed"); 
  };
  // Update Course Status API
const updateCourseStatus = async (token, id, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${BASE_URL}/admin/course/status/${id}`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Updated Course Status api failed');
  };
  // 
const getUserCourseProgress = async (token,courseId) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/course/progress/${courseId}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("User Course Progress api failed"); 
  };

  const enrolledUserProgressGet = async (token,courseId) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/course/instructor/progress/${courseId}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("enrolled user progress api failed"); 
  };

const enrolledCourse = async (token,data) => { 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }; 
    const response = await axios.post(
      `${BASE_URL}/admin/enrolled/course`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Enrolled Course api failed"); 
    };

const addSection = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(`${BASE_URL}/admin/course/section/add`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Add Course Section api failed');
  };
const getSection = async (token, courseId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/course/section/${courseId}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Section List api failed');
  };
const updateSection = async (token, id, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${BASE_URL}/admin/course/section/${id}`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Updated Section api failed');
  };
const deleteSection = async (token, id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.delete(`${BASE_URL}/admin/course/section/${id}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Delete Section api failed');
  };
  
const addLecture = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(`${BASE_URL}/admin/course/lecture/add`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Add Course Lecture api failed');
  };
const updateLecture = async (token, id, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${BASE_URL}/admin/course/lecture/${id}`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Updated Lecture api failed');
  };
const deleteLecture = async (token, id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.delete(`${BASE_URL}/admin/course/lecture/${id}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Delete Lecture api failed');
  };

const markLectureComplete = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(`${BASE_URL}/admin/course/lecture/complete`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Course Lecture Complete api failed');
  };

const addContent = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(`${BASE_URL}/admin/course/lecture/content/add`, data, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Add Content api failed');
  };
const updateContent = async (token, id, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/course/lecture/content/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    }
    throw new Error('Updated Content api failed');
  };
const deleteContent = async (token, id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.delete(`${BASE_URL}/admin/course/lecture/content/${id}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Delete Content api failed');
  };
const courseContentGet = async (token, courseId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/course/${courseId}`, config);
    if (response.data) {
      return response.data;
    }
    throw new Error('Course Content List api failed');
  };

  const userCourseStatisticsGet = async (token) => {  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await axios.get(
        `${BASE_URL}/admin/statistics/course`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Course Statistics api failed"); 
    };

    const userCoursesProgressGet = async (token) => {  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
        const response = await axios.get(
          `${BASE_URL}/admin/user/course/prograss`,
          config
        );
        if (response.data) {
          return response.data;
        } 
        throw new Error("Course Prograss api failed"); 
      };
  

  const courseServices = {
    addCourse,
    getCourse,
    updateCourse,
    updateCourseStatus, 
    getSingleCourse,
    getReviewCourses,
    getPublishCourse,
    getUserCourseProgress,
    enrolledCourse,
    enrolledCourseGet,
    enrolledUserProgressGet,
    addSection,
    getSection,
    updateSection,    
    deleteSection,
    addLecture,
    updateLecture,
    deleteLecture,
    markLectureComplete,
    addContent,
    updateContent,
    deleteContent,
    courseContentGet,
    userCourseStatisticsGet,
    userCoursesProgressGet
};

export default courseServices;
