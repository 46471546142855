import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addAttendance = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };     
  const response = await axios.post(
    `${BASE_URL}/admin/attendance`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Attendance api failed"); 
};

const userAttendance = async (token,id,pageno,limit,fromDate,toDate,business) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
    const response = await axios.get(
      `${BASE_URL}/admin/attendance/${id}?business=${business}&page=${pageno}&perPage=${limit}&fromDate=${fromDate?? ''}&toDate=${toDate??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Attendance List api failed"); 
  };

  
const getUserTodayAttendance = async (token,businessId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
    const response = await axios.get(
      `${BASE_URL}/admin/attendence/today/${businessId}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Today Attendance api failed"); 
  };

  const getBussinessUserAttendanceList = async (token,userId,businessId,pageno,limit,fromDate,toDate) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };   
      const response = await axios.get(
        `${BASE_URL}/admin/attendance/bussinessuser/${userId}/${businessId}?page=${pageno}&perPage=${limit}&fromDate=${fromDate?? ''}&toDate=${toDate??''}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Get Today Attendance api failed"); 
    };
  
  const allAttendance = async (token,pageno,limit,fromDate,toDate) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };     
    const response = await axios.get(
      `${BASE_URL}/superAdmin/attendance/all?page=${pageno}&perPage=${limit}&fromDate=${fromDate?? ''}&toDate=${toDate??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get All Attendance List api failed"); 
  };

  const getTotalDurationForCurrentMonth = async (token,business,user,startDate,endDate) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };   
      const response = await axios.get(
        `${BASE_URL}/admin/attendance/monthly/${business}/${user}?startDate=${startDate??''}&endDate=${endDate??''}`,
        config
      );
      if (response.data) {
        return response.data;
      } 
      throw new Error("Get Today Attendance api failed"); 
    };
  
const attendanceServices = {
    addAttendance,
    userAttendance,
    allAttendance,
    getBussinessUserAttendanceList,
    getUserTodayAttendance,
    getTotalDurationForCurrentMonth
};

export default attendanceServices;
